<template lang="html">
  <v-model-table ref="patientsTable" model-name="VaccinePatientsList" :model-api="['models','Document','Documents']"
    :model-api-search="vaccinePatientsListQuery"
    api-order-by="created_at"
    api-with="mophsent"
    model-title="รายการผู้รับวัคซีนที่ยังไม่ได้ส่งข้อมูล"
    :headers="headers" :insertable="false" serverPagination expandable>
    <template v-slot:toolBarButton>
      <v-btn color="primary" @click="resendAll">Resend All</v-btn>
    </template>
    <template v-slot:item.patient.name_th="props">
      <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
    </template>
    <template v-slot:item.data.productCode="props">
      <v-label-master group-key="covid19Vaccine" :item-code="props.item.data.productCode"></v-label-master>
    </template>
    <template v-slot:item.mophsent="props">
      {{ (props.item.mophsent) ? props.item.mophsent.length : "-" }}
    </template>
    <template v-slot:item.action="props">
      <v-btn @click="resendMoph(props.item.id)" color="primary" class="mx-1">Resend</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers,item,actions }">
      <td :colspan="headers.length" class="py-2">
        <v-data-table :items="item.mophsent" :headers="mophSentHeaders">
          <template v-slot:item.created_at="props">
            <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
          </template>
          <template v-slot:item.isSuccess="props">
            <v-icon :color="(props.item.isSuccess) ? 'green darken-2' : 'red'">{{ (props.item.isSuccess) ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}</v-icon>
          </template>
          <template v-slot:item.responseData="props">
            <v-json-pretty :data="props.item.responseData" :deep="1"></v-json-pretty>
          </template>
        </v-data-table>
      </td>
    </template>
  </v-model-table>
</template>

<script>
import vJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';
import api from 'tantee-common/lib/api'

export default {
  components: {
    vJsonPretty,
  },
  data: ()=>({
    headers: [
      {
        text: 'วันที่ฉีดวัคซีน',
        value: 'data.adminDateTime',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'ชนิดวัคซีน',
        value: 'data.productCode',
        class: 'body-2'
      },
      {
        text: 'Lot No',
        value: 'data.lotNo',
        class: 'body-2'
      },
      {
        text: 'Serial No',
        value: 'data.serialNo',
        class: 'body-2'
      },
      {
        text: 'Attempt',
        value: 'mophsent',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '100px',
        sortable: false,
        class: 'body-2'
      }
    ],
    mophSentHeaders: [
      {
        text: 'วันที่ส่งข้อมูล',
        value: 'created_at',
        class: 'body-2',
        width: '300px'
      },
      {
        text: 'สำเร็จ',
        value: 'isSuccess',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'รายละเอียด',
        value: 'responseData',
        class: 'body-2'
      },
    ]
  }),
  computed: {
    vaccinePatientsListQuery() {
      let tmpQuery = {
        templateCode: 'cv19-vaccine-administration',
        status: 'approved',
        'doesntHave#mophsentsuccess': true,
      }
      return tmpQuery
    }
  },
  methods: {
    resendMoph(documentId) {
      this.$confirm("ต้องการส่งข้อมูลไป MOPH IC อีกครั้ง").then((res)=>{
        if (res) {
          let confirmPromise = api.post(['Export','MOPHExportController','sendSingleDataFromId'],{documentId: documentId}).then((returnData)=>{
            if (returnData.success) {
              this.$refs.patientsTable.loadData()
            }
          })
          this.$loading('Sending',confirmPromise)
        }
      })
    },
    resendAll() {
      this.$confirm("ต้องการส่งข้อมูลที่ยังส่งไม่สำเร็จไป MOPH IC อีกครั้ง").then((res)=>{
        if (res) {
          api.post(['Export','MOPHExportController','resendFailedData']).then((returnData)=>{
            if (returnData.success) {
              this.$notify('ระบบกำลังดำเนินการส่งข้อมูล')
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
